import React from "react";
import {Button, CalendarPicker, Input, Panel, Select, Notification} from "@appkit4/react-components";
import Layout from "../Layout/Layout";
import {HeaderType} from "../../types/HeaderType";
import {useTr} from "../../utils/trUtil";
import {useParams, useNavigate, useSearchParams} from "react-router-dom";
import "./Create.scss";
import {useGlobalStore} from "../../stores/GlobalStore";
import {FormLabel} from "react-bootstrap";
import isValidDate from "../../utils/isValidDate";
import * as O from "fp-ts/Option";
import {Calculation} from "../../types/Calculation";
import ReviewersManager from "./ReviewersManager";
import {stripHtml} from "../../utils/stripHtml";
import {Form} from "../../types/Form";
import i18n from "../../utils/i18n";
import {dateToIsoString} from "../../utils/dateToIsoString";
import BackButton from "../../misc/BackButton";
import CalculationQuestionnaireAndAttachmentsMembers from "../MembersManagement/CalculationQuestionnaireAndAttachmentsMembers";
import {ClaimTypes} from "../../Constants";

declare type FormError = {
    key: string;
    message: string;
};

declare type PropTypes = {
    calculation?: Calculation;
};

const Create: React.FC<PropTypes> = (props: PropTypes) => {
    const tr = useTr("calculation_create");
    const trs = useTr("settings");
    const formTr = useTr("form");
    const trg = useTr("global");
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const getEntityByHash = useGlobalStore((s) => s.getEntityByHash);
    const codebooks = useGlobalStore((s) => s.codebooks);
    const fetch = useGlobalStore((s) => s.fetchApi);
    const [entity, setEntity] = React.useState(
        typeof params.entityHash === "undefined" ? O.none : getEntityByHash(params.entityHash)
    );
    const updateCurrentCalculation = useGlobalStore((s) => s.updateCurrentCalculation);
    const reviewers = useGlobalStore((s) => s.currentCalculationReviewers);

    const hasUserClaim = useGlobalStore((s) => s.hasUserClaim);
    const currentCalculation = useGlobalStore((s) => s.currentCalculation);
    const currentClient = useGlobalStore((s) => s.currentClient);

    const [errors, setErrors] = React.useState<FormError[]>([]);
    const [responseErrors, setResponseErrors] = React.useState([]);
    const [calculation, setCalculation] = React.useState<Calculation>();
    const [availableForms, setAvailableForms] = React.useState<Form[]>();

    const [name, setName] = React.useState<string>("");
    const [description, setDescription] = React.useState<string>("");
    const [language, setLanguage] = React.useState<string>("EN");
    const [form, setForm] = React.useState<string>("");
    const [ruleset, setRuleset] = React.useState<string>("");
    const [questionnaire, setQuestionnaire] = React.useState<string>("");
    const [questionView, setQuestionView] = React.useState<string>("");
    const [periodStart, setPeriodStart] = React.useState<Date>();
    const [periodEnd, setPeriodEnd] = React.useState<Date>();
    const [notificationOpen, setNotificationOpen] = React.useState<boolean>(true);

    React.useEffect(() => {
        setEntity(typeof params.entityHash === "undefined" ? O.none : getEntityByHash(params.entityHash));
    }, [params, getEntityByHash]);

    React.useEffect(() => {
        if (calculation) setAvailableForms(calculation.available_forms);
    }, [calculation]);

    React.useEffect(() => {
        if (props.calculation) {
            setCalculation(props.calculation);
            setName(props.calculation.name);
            setLanguage(props.calculation.language);
            setPeriodStart(new Date(new Date(props.calculation.period_start).getTime() - 12 * 60 * 60 * 1000));
            setPeriodEnd(new Date(new Date(props.calculation.period_end).getTime() - 12 * 60 * 60 * 1000));
            setDescription(props.calculation.description);
            setForm(props.calculation.form_id + "");
            setRuleset(props.calculation.ruleset_id + "");
            setQuestionnaire(props.calculation.questionnaire_id + "");
            setQuestionView(props.calculation.question_view_id + "");
        } else {
            setPeriodStart(new Date(new Date().getFullYear(), 0, 1, 0, 0, 0));
            setPeriodEnd(new Date(new Date().getFullYear(), 11, 31, 23, 59, 59));
        }
    }, [props]);

    const isFormValid = (): boolean => {
        setErrors([]);
        let isValid = true;
        if (name === "") {
            setErrors((s) => [...s, {key: "name", message: trg("name_of_calculation_is_empty")}]);
            isValid = false;
        }
        if (!isValidDate(periodStart)) {
            setErrors((s) => [...s, {key: "periodStart", message: trg("select_starting_period_date")}]);
            isValid = false;
        }
        if (!isValidDate(periodEnd)) {
            setErrors((s) => [...s, {key: "periodEnd", message: trg("select_ending_period_date")}]);
            isValid = false;
        }
        return isValid;
    };

    const inputHasError = (name: string): boolean => {
        let hasError = errors.findIndex((x) => x.key === name) > -1;
        return hasError;
    };

    React.useEffect(() => {
        if (O.isSome(entity) && ruleset !== "") {
            fetch(`/entity/${entity.value.hash}/available_forms/?ruleset_id=${ruleset}`, "get")
                .then((res) => {
                    return res.json();
                })
                .then((result) => {
                    if (typeof result.error !== "undefined") {
                        setResponseErrors(result.error.details);
                    } else {
                        setAvailableForms(result);
                    }
                })
                .catch((error) => {
                    setResponseErrors(error);
                });
        }
    }, [ruleset, entity, fetch]);

    const submitForm = () => {
        if (!isFormValid()) return false;

        let data = {
            name: name,
            language: language,
            period_start: periodStart ? dateToIsoString(new Date(periodStart.getTime() + 12 * 60 * 60 * 1000)) : null,
            period_end: periodEnd ? dateToIsoString(new Date(periodEnd.getTime() + 12 * 60 * 60 * 1000)) : null,
            // period_start: periodStart ? periodStart : null,
            // period_end: periodEnd ? periodEnd : null,
            description: description,
            form_id: form.length ? form : 1,
            ruleset_id: ruleset.length ? ruleset : 1,
            questionnaire_id: questionnaire.length ? questionnaire : 1,
            question_view_id: questionView.length ? questionView : 1,
        };

        if (O.isSome(entity)) {
            if (calculation) {
                fetch(`/entity/${entity.value.hash}/calculation/${calculation.hash}/`, "patch", data)
                    .then((res) => {
                        return res.json();
                    })
                    .then((result) => {
                        if (typeof result.error !== "undefined") {
                            setResponseErrors(result.error.details);
                        } else if (O.isSome(entity)) {
                            updateCurrentCalculation(result);
                            navigate(`/calculation/${entity.value.hash}/${result.hash}`);
                        }
                    });
            } else {
                fetch(`/entity/${entity.value.hash}/calculation/`, "post", data)
                    .then((res) => {
                        return res.json();
                    })
                    .then((result) => {
                        if (typeof result.error !== "undefined") {
                            setResponseErrors(result.error.details);
                        } else {
                            navigate(`/calculation/${entity.value.hash}/${result.hash}/settings?created=true`);
                        }
                    });
            }
        }
    };

    const languages = Object.entries(codebooks.languages).map(([key, lang]) => {
        return {
            key: key,
            value: key,
            label: lang,
        };
    });
    return (
        <Layout headerType={calculation ? HeaderType.Calculation : HeaderType.Blank}>
            <div className="d-flex">
                <div className="flex-grow-1"></div>
                <div>
                    <BackButton />
                </div>
            </div>
            {O.isSome(entity) && (
                <Panel className="calculation_create_panel">
                    <h2 className="d-inline">{calculation ? calculation.name : tr("label")}:&nbsp;</h2>
                    <Button
                        kind="text"
                        className="entity-name d-inline"
                        onClick={() => navigate(`/settings/entities/${entity.value.hash}`)}
                    >
                        {entity.value.name}
                    </Button>
                    {Boolean(searchParams.get("created")) === true && notificationOpen === true && (
                        <Notification
                            title=""
                            className="w-100"
                            message={<div>{trg("calculation_was_successfully_created")}</div>}
                            status="success"
                            onClose={() => setNotificationOpen(false)}
                        ></Notification>
                    )}
                    <div className="container p-3">
                        {Object.entries(responseErrors).map((row) => {
                            return (
                                <div key={row[0]}>
                                    <Notification status="error" message={`${row[0]} - ${row[1][0]}`} />
                                </div>
                            );
                        })}
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <FormLabel required={true} className="mb-1">
                                    {tr("name")}
                                </FormLabel>
                                <Input
                                    hideTitleOnInput={true}
                                    error={inputHasError("name")}
                                    title={formTr("typeHere")}
                                    type="text"
                                    value={name}
                                    onChange={(v: string) => {
                                        setName(stripHtml(v));
                                    }}
                                />
                            </div>
                            <div className="col-md-6">
                                <FormLabel required={true} className="mb-1">
                                    {tr("language")}
                                </FormLabel>
                                <Select
                                    value={language}
                                    placeholder={tr("selectDesiredLanguage")}
                                    className={inputHasError("language") ? "error" : ""}
                                    data={languages}
                                    multiple={false}
                                    defaultActiveFirstOption={true}
                                    dropdownMatchWidth={false}
                                    onSelect={(v: any) => {
                                        setLanguage(v);
                                        i18n.changeLanguage(v.toLowerCase());
                                    }}
                                >
                                    {/* {Object.entries(codebooks.languages).map(([key, value]) => {
                                        return <option>{value}</option>;
                                    })} */}
                                </Select>
                            </div>
                        </div>
                        {false && (
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <FormLabel required={false} className="mb-1">
                                        {tr("type")}
                                    </FormLabel>
                                    <Input
                                        hideTitleOnInput={true}
                                        error={inputHasError("type")}
                                        title={tr("selectCalculationType")}
                                        type="text"
                                        disabled
                                    />
                                </div>
                                <div className="col-md-6">
                                    <FormLabel className="mb-1">{tr("previousCalculation")}</FormLabel>
                                    <Input
                                        hideTitleOnInput={true}
                                        error={inputHasError("calculationRef")}
                                        title={tr("selectCalculation")}
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row mt-3">
                            {/* <div className="col-md-6">
                                <FormLabel required={true} className="mb-1">
                                    {formTr("periodRange")}
                                </FormLabel>
                                {periodStart && periodEnd && (
                                    <CalendarPicker
                                        fieldTitle={formTr("selectPeriodDateRange")}
                                        className={
                                            inputHasError("periodStart") || inputHasError("periodEnd") ? "error" : ""
                                        }
                                        //placeholder="mm/dd/yyyy     --->     mm/dd/yyyy"
                                        locale={i18n.language}
                                        style={{width: "100%"}}
                                        rangeConnecterFormat=" --"
                                        selectRange={true}
                                        editable={true}
                                        value={[periodStart, periodEnd]}
                                        //value={[periodStart, periodEnd]}
                                        //defaultValue={new Date(new Date().getFullYear(), 0, 1, 0, 0, 0)}
                                        onChange={(v: Date[], isValid: boolean) => {
                                            if (isValid) {
                                                if (v[0]) setPeriodStart(v[0]);
                                                if (v[1]) setPeriodEnd(v[1]);
                                            }
                                        }}
                                        mode={"showDoubleCalendar"}
                                    />
                                )}
                            </div> */}
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <FormLabel required={true} className="mb-1">
                                            {formTr("periodRange")}
                                        </FormLabel>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <CalendarPicker
                                            fieldTitle={formTr("selectPeriodDateRange")}
                                            className={inputHasError("periodStart") ? "error" : ""}
                                            locale={i18n.language}
                                            style={{width: "100%"}}
                                            editable={false}
                                            value={periodStart ? periodStart : undefined}
                                            onChange={(v: Date, isValid: boolean) => {
                                                if (isValid && v) setPeriodStart(v);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <CalendarPicker
                                            fieldTitle={formTr("selectPeriodDateRange")}
                                            className={inputHasError("periodEnd") ? "error" : ""}
                                            locale={i18n.language}
                                            style={{width: "100%"}}
                                            editable={false}
                                            value={periodEnd ? periodEnd : undefined}
                                            onChange={(v: Date, isValid: boolean) => {
                                                if (isValid && v) setPeriodEnd(v);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <FormLabel required={false} className="mb-1">
                                    {tr("internalDescription")}
                                </FormLabel>
                                <Input
                                    error={inputHasError("description")}
                                    hideTitleOnInput={true}
                                    title={formTr("typeHere")}
                                    type="text"
                                    value={description}
                                    onChange={(v) => setDescription(stripHtml(v))}
                                />
                            </div>
                        </div>
                        {calculation && availableForms && (
                            <>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <FormLabel className="mb-1">{trg("ruleset")}</FormLabel>
                                        <Select
                                            value={ruleset}
                                            placeholder={trg("select_relevant_form_for")}
                                            className={inputHasError("ruleset") ? "error" : ""}
                                            data={calculation.available_rulesets.map((v) => ({
                                                key: v.id + "",
                                                value: v.id + "",
                                                label: v.name,
                                            }))}
                                            multiple={false}
                                            dropdownMatchWidth={false}
                                            onSelect={(v: any) => {
                                                setRuleset(v);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <FormLabel className="mb-1">{trg("form")}</FormLabel>
                                        <Select
                                            value={form}
                                            placeholder={trg("select_relevant_form_for")}
                                            className={inputHasError("form") ? "error" : ""}
                                            data={availableForms.map((v) => ({
                                                key: v.id + "",
                                                value: v.id + "",
                                                label: v.name,
                                            }))}
                                            multiple={false}
                                            dropdownMatchWidth={false}
                                            onSelect={(v: any) => {
                                                setForm(v);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <FormLabel className="mb-1">{trg("questionnaire")}</FormLabel>
                                        <Select
                                            value={questionnaire}
                                            placeholder={trg("select_relevant_questionnaire")}
                                            className={inputHasError("questionnaire") ? "error" : ""}
                                            data={calculation.available_questionnaires.map((v) => ({
                                                key: v.id + "",
                                                value: v.id + "",
                                                label: v.name,
                                            }))}
                                            multiple={false}
                                            dropdownMatchWidth={false}
                                            onSelect={(v: any) => {
                                                setQuestionnaire(v);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <FormLabel className="mb-1">{trg("questionnaire_view")}</FormLabel>
                                        <Select
                                            value={questionView}
                                            placeholder={trg("selectrelevant_questionnaire_view")}
                                            className={inputHasError("questionnaire_view") ? "error" : ""}
                                            data={calculation.available_question_views.map((v) => ({
                                                key: v.id + "",
                                                value: v.id + "",
                                                label: v.name,
                                            }))}
                                            multiple={false}
                                            dropdownMatchWidth={false}
                                            onSelect={(v: any) => {
                                                setQuestionView(v);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <FormLabel className="mb-1">{trs("show_questionnaire_users")}</FormLabel>
                                        {O.isSome(entity) &&
                                            O.isSome(currentCalculation) &&
                                            O.isSome(currentClient) &&
                                            (hasUserClaim(ClaimTypes.ENTITY, `${entity.value.hash}`) ||
                                                hasUserClaim(ClaimTypes.CLIENT, `${currentClient.value.hash}`) ||
                                                hasUserClaim(
                                                    ClaimTypes.ENTITY_CALCULATION,
                                                    `${currentCalculation.value.hash}`
                                                )) && (
                                                <div className="mb-1">
                                                    <CalculationQuestionnaireAndAttachmentsMembers
                                                        entity={entity.value}
                                                        calculation={currentCalculation.value}
                                                    />
                                                </div>
                                            )}
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <FormLabel className="mb-1">{tr("reviewers_1")}</FormLabel>
                                        <ReviewersManager
                                            key="first"
                                            type="first"
                                            calculation={calculation}
                                            entity={entity.value}
                                            reviewers={reviewers}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <FormLabel className="mb-1">{tr("reviewers_2")}</FormLabel>
                                        <ReviewersManager
                                            key="second"
                                            type="second"
                                            calculation={calculation}
                                            entity={entity.value}
                                            reviewers={reviewers}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <Button kind="secondary" onClick={() => navigate(`/`)}>
                                    {tr("cancel")}
                                </Button>
                            </div>
                            <div className="col-md-6">
                                <Button className="float-end" onClick={submitForm}>
                                    {calculation ? tr("saveAndClose") : tr("createAndNext")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Panel>
            )}
        </Layout>
    );
};

export default Create;
