import React, {useCallback, useMemo, useRef} from "react";
import CanShowAdminMiddleware from "../../../misc/CanShowAdminMiddleware";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {Table, Column} from "@appkit4/react-components/table";
import {List, ListItem} from "@appkit4/react-components/list";
import {Loading, Badge, Modal, Input, Button, CalendarPicker} from "@appkit4/react-components";
import {DjangoDefaultPaginationType} from "../../../types/DjangoDefaultPaginationType";
import {Pagination} from "@appkit4/react-components/pagination";
import {Switch} from "@appkit4/react-components/switch";
import Centered from "../../../misc/Centered";
import useQuestionnaireSelection from "./Forms/useQuestionnaireSelection";
import ClassNames from "classnames";
import "./AdminFixtureQuestionnaire.scss";
import QuestionForm from "./Forms/QuestionForm";
import {Question} from "../../../types/Question";
import {useTr} from "../../../utils/trUtil";
import i18n from "../../../utils/i18n";
import {Questionnaire} from "../../../types/Questionnaire";
import isValidDate from "../../../utils/isValidDate";

declare type PropTypes = {};

interface PaginationType extends DjangoDefaultPaginationType {
    results: Question[];
}

const AdminFixtureQuestionnaire: React.FC<PropTypes> = (props: PropTypes) => {
    const [data, dispatch] = React.useReducer(questionnaireQuestionReducer, []);

    const [sortedIds, setSortedIds] = React.useState<string[]>();

    const [totalCount, setTotalCount] = React.useState<number>(0);
    const [countPerPage, setCountPerPage] = React.useState<number>(100);
    const [current, setCurrent] = React.useState(1);
    const [dataAreLoaded, setDataAreLoaded] = React.useState<boolean>(false);
    const [dataAreLoading, setDataAreLoading] = React.useState<boolean>(false);
    const [allowedSorting, setAllowedSorting] = React.useState<boolean>(false);
    const [copyModalVisible, setCopyModalVisible] = React.useState<boolean>(false);
    const [questionnaireData, setQuestionnaireData] = React.useState<Questionnaire>({
        id: 0,
        name: "",
        name_sk: "",
        valid_from: undefined,
        valid_to: undefined,
        version: 1,
    });
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [selectedId, setSelectedId] = React.useState("");

    const questionnaireRef = useRef<any>();

    const {questionnaireSelector, selectedQuestionnaire, setSelectedQuestionnaire} = useQuestionnaireSelection(
        dataAreLoading,
        questionnaireRef
    );

    const {fetchApi, addErrorNotification, addSuccessNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
        addSuccessNotification: s.addSuccessNotification,
    }));

    const onPageChange = (page: number) => {
        setCurrent(page);
        setDataAreLoading(true);
    };

    const trg = useTr("global");

    React.useEffect(() => {
        if (selectedQuestionnaire) {
            setQuestionnaireData(selectedQuestionnaire);
        }
        setDataAreLoading(true);
    }, [selectedQuestionnaire]);

    React.useEffect(() => {
        if (sortedIds && selectedQuestionnaire && allowedSorting) {
            fetchApi(`/settings/_/fixtures/question/prioritize_questions_based_on_list/`, "patch", sortedIds)
                .then((r) => r.json())
                .then((res) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    }
                })
                .catch((err) => {
                    addErrorNotification(err);
                });
        }
    }, [sortedIds, selectedQuestionnaire, fetchApi, addErrorNotification, allowedSorting]);

    React.useEffect(() => {
        if (selectedQuestionnaire && (dataAreLoaded === false || dataAreLoading === true)) {
            fetchApi(
                `/settings/_/fixtures/question/?questionnaire=${selectedQuestionnaire.id}&page=${current}&page_size=${countPerPage}`
            )
                .then((r) => r.json())
                .then((res: PaginationType | any) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        dispatch({type: "INIT", items: res.results});
                        setTotalCount(res.count);
                        setSortedIds(res.results.map((res: Question) => res.id + ""));
                    }
                    setDataAreLoading(false);
                    setDataAreLoaded(true);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setDataAreLoading(false);
                    setDataAreLoaded(true);
                });
        }
    }, [dataAreLoaded, addErrorNotification, fetchApi, dataAreLoading, current, countPerPage, selectedQuestionnaire]);

    const renderActionCell = (row: Question, field) => {
        return (
            selectedQuestionnaire && (
                <>
                    <QuestionForm
                        question={row}
                        questionnaire={selectedQuestionnaire}
                        onSaveHandler={() => setDataAreLoaded(false)}
                    />
                </>
            )
        );
    };

    const handlePaginationChange = (val: number) => {
        setCountPerPage(val);
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setDataAreLoading(true);
        }, 2000);
    };

    const pagination = React.useMemo(
        () => (
            <div className="row">
                <div className="col-md-6 mt-3 d-flex">
                    <Pagination
                        current={current}
                        total={Math.ceil(totalCount / countPerPage)}
                        onPageChange={onPageChange}
                    />
                    <Input
                        type="number"
                        value={countPerPage}
                        title={"Count per Page"}
                        onChange={handlePaginationChange}
                        disabled={!dataAreLoaded}
                        className="ml-3"
                    />
                </div>
            </div>
        ),
        [totalCount, countPerPage, current]
    );

    const table = (
        <>
            {pagination}
            <Table className="table-responsive" originalData={data} hasTitle striped condensed>
                <Column field="id">ID</Column>
                <Column field="id" title={"Action"} renderCell={renderActionCell}>
                    {trg("action")}
                </Column>
                <Column field="copy_of">{trg("copy_of")}</Column>
                <Column
                    field="name"
                    renderCell={(row) => (
                        <div className="small" style={{width: "380px"}}>
                            <p>{row.name}</p>
                            <small>
                                <i>{row.description}</i>
                            </small>
                        </div>
                    )}
                >
                    {trg("name")}
                    <br />
                    <i>{trg("description")}</i>
                </Column>

                <Column field="type">{trg("type")}</Column>
                <Column
                    field="choices"
                    renderCell={(row) => <div style={{width: "380px"}}>[{row.choices.join(", ")}]</div>}
                >
                    {trg("choices")}
                </Column>
                <Column field="adjustment_type">{trg("adjustment_type")}</Column>
                <Column field="attachments_upload_allowed">{trg("attachement_allowed")}</Column>
                <Column field="views" renderCell={(row) => <>{row.views.join(",")}</>}>
                    {trg("views")}
                </Column>
                <Column field="equivalent_id">{trg("equivalent_id")}</Column>
                <Column field="formula">{trg("formula")}</Column>
                <Column field="priority">{trg("priority")}</Column>
                <Column field="version">{trg("version")}</Column>
                <Column field="parent">{trg("parent")}</Column>
            </Table>
            {pagination}
        </>
    );

    const handleKeyDown = (item: any, event: React.KeyboardEvent) => {
        if (event.key === "Enter" || event.key === " ") {
            setSelectedId(item.id);
        }
    };

    const renderItem = useCallback(
        (item: any, index: number, isDragging?: boolean, iskeyBoardDragging?: boolean) => {
            const classes = ClassNames({
                selected: item.id === selectedId,
            });

            return (
                <ListItem
                    item={item}
                    index={index}
                    aria-selected={parseInt(item.id) === parseInt(selectedId)}
                    onClick={() => {
                        setSelectedId(item.id + "");
                    }}
                    className={classes}
                    onKeyDown={(e) => handleKeyDown(item, e)}
                    divider={false}
                    style={{width: "100%"}}
                >
                    <div className="ap-list-item-draggable">
                        <span
                            className={`Appkit4-icon ${
                                isDragging && iskeyBoardDragging ? "icon-elevator-outline" : "icon-menu-outline"
                            }`}
                        ></span>
                        <div className="ap-list-item-draggable-box">
                            <div className="ap-list-item-draggable-body">
                                <span className="primary-text">
                                    {item.id} ({trg("copy_of")}: {item.copy_of}) - {item.name}
                                </span>

                                <span className="secondary-text">
                                    &nbsp;|&nbsp;
                                    <i>
                                        <strong>{item.type}</strong>
                                    </i>
                                </span>
                            </div>
                            <div className="ap-list-item-draggable-body">{item.description}</div>
                        </div>
                    </div>
                </ListItem>
            );
        },
        [selectedId]
    );

    const handleOnDragEnd = useCallback(() => {
        setTimeout(() => {
            const el = document.getElementById("draggable-question-list");
            if (el) {
                let arr: string[] = [];
                for (const child of el.children) {
                    for (const ch of child.children) {
                        let val = ch.getAttribute("data-rbd-draggable-id");
                        if (typeof val === "string") arr.push(val);
                    }
                }
                setSortedIds(arr);
            }
        }, 1000);
    }, []);

    const sorting = useMemo(
        () => (
            <div>
                <List
                    id="draggable-question-list"
                    draggable
                    data={data.map((i) => ({...i, id: i.id + ""}))}
                    itemKey="id"
                    bordered={true}
                    renderItem={renderItem}
                    width={"100%"}
                    style={{display: "inline-block", padding: 8}}
                    onDragEnd={handleOnDragEnd}
                ></List>
            </div>
        ),
        [data, renderItem, handleOnDragEnd]
    );

    const copyQuestionnaire = () => {
        if (selectedQuestionnaire) {
            fetchApi(`/settings/_/fixtures/question/copy_questionnaire/`, "post", questionnaireData)
                .then((r) => r.json())
                .then((res) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        addSuccessNotification(trg("saved_successfully"));
                        setDataAreLoaded(false);
                        setDataAreLoading(false);
                        setCopyModalVisible(false);
                        if (questionnaireRef.current) {
                            questionnaireRef.current.fetchQuestionnaires();
                        }
                    }
                })
                .catch((err) => {
                    addErrorNotification(err);
                });
        }
    };

    return (
        <>
            <CanShowAdminMiddleware>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">{questionnaireSelector}</div>
                        {selectedQuestionnaire && (
                            <div className="col" style={{marginTop: "7px"}}>
                                <Button
                                    icon="Appkit4-icon icon-copy-outline"
                                    onClick={setCopyModalVisible}
                                    compact
                                    loading={dataAreLoading}
                                >
                                    Copy Selected Questionnaire
                                </Button>
                            </div>
                        )}
                        {selectedQuestionnaire && (
                            <div className="col text-end">
                                <Switch
                                    showIndicator
                                    className="float-end me-2 mt-1"
                                    checked={allowedSorting}
                                    onChange={(v) => {
                                        setAllowedSorting(v);
                                        setDataAreLoaded(false);
                                    }}
                                    disabled={dataAreLoading}
                                >
                                    {allowedSorting ? "Sorting allowed" : "Allow sorting"}
                                </Switch>
                            </div>
                        )}
                    </div>
                    {selectedQuestionnaire ? (
                        !dataAreLoading ? (
                            allowedSorting ? (
                                sorting
                            ) : (
                                table
                            )
                        ) : (
                            <div className="m-5 p-5">
                                <Centered>
                                    <Loading loadingType="circular" indeterminate={true} compact={false}></Loading>
                                </Centered>
                            </div>
                        )
                    ) : (
                        <div className="bg-light my-3 p-3">{trg("select_the_relevant")}</div>
                    )}
                </div>
                {selectedQuestionnaire && (
                    <QuestionForm questionnaire={selectedQuestionnaire} onSaveHandler={() => setDataAreLoaded(false)} />
                )}
            </CanShowAdminMiddleware>
            <Modal
                visible={copyModalVisible}
                title={trg("questionnaire")}
                ariaLabel={trg("questionnaire")}
                onCancel={() => setCopyModalVisible(false)}
                modalStyle={{width: "43.75rem"}}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={
                    selectedQuestionnaire && (
                        <Badge type={"warning"} value={trg("copying")} style={{marginTop: "-8px"}}></Badge>
                    )
                }
                icons={""}
                footer={
                    <>
                        <Button onClick={() => setCopyModalVisible(false)} kind="secondary">
                            {trg("cancel")}
                        </Button>
                        <Button
                            onClick={() => {
                                copyQuestionnaire();
                                setDataAreLoading(true);
                            }}
                            loading={dataAreLoading}
                        >
                            {trg("save")}
                        </Button>
                    </>
                }
                bodyStyle={{minHeight: "92px"}}
            >
                <div className="row">
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("name")}
                            value={questionnaireData.name || ""}
                            required
                            onChange={(val: string) => setQuestionnaireData((s) => ({...s, name: val}))}
                            disabled={dataAreLoading}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("name_sk")}
                            value={questionnaireData.name_sk}
                            required
                            onChange={(val: string) => setQuestionnaireData((s) => ({...s, name_sk: val}))}
                            disabled={dataAreLoading}
                        ></Input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <div className="row justify-content-between">
                            <div className="col-md-6">
                                <CalendarPicker
                                    key={1}
                                    format="YYYY-MM-DD"
                                    placeholder="YYYY-MM-DD"
                                    value={
                                        questionnaireData.valid_from
                                            ? new Date(questionnaireData.valid_from)
                                            : undefined
                                    }
                                    onChange={(v: Date, isValid: boolean) => {
                                        if (isValid && isValidDate(v) && v) {
                                            setQuestionnaireData((s) => ({...s, valid_from: v}));
                                        }
                                    }}
                                    locale={i18n.language}
                                    required
                                    fieldTitle={trg("valid_from")}
                                    disabled={dataAreLoading}
                                />
                            </div>
                            <div className="col-md-6">
                                <CalendarPicker
                                    key={2}
                                    format="YYYY-MM-DD"
                                    placeholder="YYYY-MM-DD"
                                    value={
                                        questionnaireData.valid_to ? new Date(questionnaireData.valid_to) : undefined
                                    }
                                    onChange={(v: Date, isValid: boolean) => {
                                        if (isValid && isValidDate(v) && v) {
                                            setQuestionnaireData((s) => ({...s, valid_to: v}));
                                        }
                                    }}
                                    locale={i18n.language}
                                    fieldTitle={trg("valid_to")}
                                    disabled={dataAreLoading}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Button
                                    compact
                                    kind="text"
                                    className="ms-auto"
                                    onClick={() => setQuestionnaireData((s) => ({...s, valid_to: null}))}
                                    disabled={dataAreLoading}
                                >
                                    {trg("clear_valid_to")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AdminFixtureQuestionnaire;

export const questionnaireQuestionReducer = (state: any, action: any) => {
    switch (action.type) {
        case "INIT":
            return action.items;
        default:
            return state;
    }
};
